import { useRef, useState } from 'react';
import styled from '@emotion/styled';
import semver from 'semver';

import { ResourceUtils } from '../../../store/ResourceUtils';
import { promptMessage } from '../../overlay/DialogPrompt';

import { useI18n } from '../../../hooks/useI18nFormatters';
import { useRootZoneId } from '../../../store/zones/useZones';
import { useAppsAttach } from '../../../store/apps/useApps';
import { useApi } from '../../../store/HomeyStore';
import { useDriversAttach, useDriversByUri } from '../../../store/drivers/useDrivers';
import { useUriDrivers } from './useUriDrivers';

import { theme } from '../../../theme/theme';
import { su } from '../../../theme/functions/su';
import { isDarkMode } from '../../../theme/classes/darkMode';

import { DialogShiftLock } from '../../overlay/DialogShiftLock';
import { Scroll } from '../../common/Scroll';
import { Icon } from '../../common/Icon';
import { IconButton } from '../../buttons/IconButton';
import { Heading2Medium } from '../../common/text/Heading2Medium';

import { SearchView } from './search-view/SearchView';
import { DriversView } from './drivers-view/DriversView';
import { ConnectView } from './connect-view/ConnectView';
import { BridgeRequiredView } from './bridge-required-view/BridgeRequiredView';
import { PairingView } from './pairing-view/PairingView';
import { MatterView } from './MatterView';

import { PairingFrame } from '../PairingFrame';
import { BackButton } from './BackButton';

import { iconCloseThin } from '../../../theme/icons/interface/close-thin';

const views = {
  search: 'search',
  driver: 'driver',
  connect: 'connect',
  bridgeRequired: 'bridgeRequired',
  pair: 'pair',
  matter: 'matter',
};

export function AddDeviceDialog(props) {
  const { i18n } = useI18n();

  useAppsAttach();
  useDriversAttach();

  const { rootZoneId } = useRootZoneId();
  const pairingFrameInstanceRef = useRef();
  const { api } = useApi();

  const [view, setView] = useState({
    id: views.search,
    args: null,
  });

  const drivers = useDriversByUri();
  const { uriDrivers, appInfo, isInstalled, isLoading } = useUriDrivers({
    uri: view.args?.uri,
  });

  // For basic drivers (e.g. zigbee) and virtual drivers/devices, go directly to the connect view.
  // Otherwise, go to the driver view.
  function handleDriverUriSelect({ uri }) {
    // Block connecting Matter devices on Homey versions < 10.3.0
    if (
      uri === 'homey:virtualdrivermatter:driver' &&
      semver.satisfies(api.homeyVersion, '<10.3.0')
    ) {
      const nextView = {
        id: views.matter,
        args: {
          // So we know to navigate back to the Homey view.
          uri: 'homey:manager:vdevice',
        },
      };
      setView(nextView);
      return;
    }

    if (uri.startsWith('homey:virtualdriver') || uri.startsWith('homey:manager:vdevice')) {
      const selectedDriver = drivers.byUri['homey:manager:vdevice'].data[uri];
      const iconId = selectedDriver?.iconObj?.id;

      const nextView = {
        id: views.connect,
        args: {
          uri: 'homey:manager:vdevice',
          driverKey: uri,
          driverInfo: {
            driverName: selectedDriver?.name,
            driverIcon: `https://icons-cdn.athom.com/${iconId}.svg`,
          },
          isInstalled: true,
          appInfo: {
            appId: 'vdevice',
            appName: 'Homey',
            appIcon: null,
            appColor: null,
            appPermissions: [],
          },
        },
      };
      setView(nextView);
      return;
    }

    setView({
      id: views.driver,
      args: {
        uri,
      },
    });
  }

  function handleDriverSelect({ driver }) {
    const driverKey = ResourceUtils.getKey(driver);
    const selectedDriver = uriDrivers?.data[driverKey];

    const nextView = {
      id: views.connect,
      args: {
        uri: view.args.uri,
        driverKey: driverKey,
        isInstalled: isInstalled,
        appInfo: appInfo,
      },
    };

    if (isInstalled) {
      const iconId = selectedDriver?.iconObj?.id;
      nextView.args.driverInfo = {
        driverName: selectedDriver?.name,
        driverIcon: `https://icons-cdn.athom.com/${iconId}.svg`,
      };
      setView(nextView);
      return;
    }

    nextView.args.driverInfo = {
      driverName: selectedDriver?.name,
      driverIcon: selectedDriver?.icon,
    };
    setView(nextView);
  }

  function hasHomeyBridge(homey) {
    return homey && Array.isArray(homey.bridges) && homey.bridges.length > 0;
  }

  function requiresHomeyBridge(connectivities, homey) {
    if (homey.model === 'homey6q') {
      // Homey Pro mini doesn't need a bridge for Zigbee
      return (
        !hasHomeyBridge(homey) &&
        Array.isArray(connectivities) &&
        connectivities.some((connectivity) =>
          ['ble', 'zwave', 'infrared', 'rf433'].includes(connectivity)
        )
      );
    }
    return (
      api.isCloud === true &&
      !hasHomeyBridge(homey) &&
      Array.isArray(connectivities) &&
      !connectivities.includes('cloud') &&
      connectivities.some((connectivity) =>
        ['ble', 'zwave', 'zigbee', 'infrared', 'rf433'].includes(connectivity)
      )
    );
  }

  // For connecting local connectivity devices (e.g. zigbee) on cloud without a Homey Bridge, go to
  // the bridgeRequired view. Otherwise, go to the pair view.
  function handleConnectPress(connectivities) {
    if (requiresHomeyBridge(connectivities, api.homey)) {
      const nextView = {
        id: views.bridgeRequired,
        args: {
          ...view.args,
          connectivities,
        },
      };
      setView(nextView);
      return;
    }

    setView({
      id: 'pair',
      args: {
        ...view.args,
      },
    });
  }

  function setSearchView() {
    setView({
      id: views.search,
      args: null,
    });
  }

  function setDriverView() {
    // Return to search view when returning from pairing view
    if (view.args.uri === 'homey:manager:vdevice') {
      setView({
        id: views.search,
        args: null,
      });
      return;
    }

    setView({
      id: views.driver,
      args: {
        uri: view.args.uri,
      },
    });
  }

  function confirmActionOnSession(action) {
    if (pairingFrameInstanceRef.current?.session) {
      promptMessage({
        type: 'confirm',
        message: i18n.messageFormatter('pair.sessionLeave'),
      })
        .then(() => {
          action();
        })
        .catch(() => {});
      return;
    }

    action();
  }

  function getView() {
    switch (view.id) {
      case views.search:
        return (
          <SearchView
            onDriverSelect={handleDriverSelect}
            onDriverUriSelect={handleDriverUriSelect}
          />
        );
      case views.driver:
        return (
          <DriversView
            isLoading={isLoading}
            appInfo={appInfo}
            uriDrivers={uriDrivers}
            onDriverSelect={handleDriverSelect}
          />
        );
      case views.connect:
        return (
          <ConnectView
            uri={view.args.uri}
            driverKey={view.args.driverKey}
            isInstalled={view.args.isInstalled}
            appInfo={view.args.appInfo}
            driverInfo={view.args.driverInfo}
            onConnectPress={handleConnectPress}
          />
        );
      case views.bridgeRequired:
        return (
          <BridgeRequiredView
            uri={view.args.uri}
            driverKey={view.args.driverKey}
            driverInfo={view.args.driverInfo}
            connectivities={view.args.connectivities}
          />
        );
      case views.matter:
        return (
          <MatterView
            uri={view.args.uri}
            driverKey={view.args.driverKey}
            isInstalled={view.args.isInstalled}
            appInfo={view.args.appInfo}
            driverInfo={view.args.driverInfo}
            onConnectPress={handleConnectPress}
          />
        );
      case views.pair:
        return (
          <PairingView>
            <PairingFrame
              instanceRef={pairingFrameInstanceRef}
              type="pair"
              driverKey={view.args.driverKey}
              zoneId={props.zoneId ?? rootZoneId}
              onDone={props.onClose}
              onCancel={setDriverView}
            />
          </PairingView>
        );
      default:
        return null;
    }
  }

  function getHeader() {
    switch (view.id) {
      case views.search:
        return (
          <AddDeviceDialog.Header>
            <Heading2Medium>{i18n.messageFormatter('header.action.device')}</Heading2Medium>
            <AddDeviceDialog.Close
              url={iconCloseThin}
              color={theme.color.icon_light}
              size={theme.icon.size_small}
              onPress={() => {
                confirmActionOnSession(props.onClose);
              }}
            />
          </AddDeviceDialog.Header>
        );
      case views.driver:
        return (
          <AddDeviceDialog.StickyHeader>
            <BackButton onPress={setSearchView}>{i18n.messageFormatter('pair.brands')}</BackButton>
            <AddDeviceDialog.Close
              url={iconCloseThin}
              color={theme.color.icon_light}
              size={theme.icon.size_small}
              onPress={() => {
                confirmActionOnSession(props.onClose);
              }}
            />
          </AddDeviceDialog.StickyHeader>
        );
      case views.connect:
        return (
          <AddDeviceDialog.StickyHeader>
            <BackButton onPress={setDriverView}>
              {view.args.appInfo?.appName === 'Homey'
                ? i18n.messageFormatter('pair.brands')
                : view.args.appInfo?.appName}
            </BackButton>
            <AddDeviceDialog.Close
              url={iconCloseThin}
              color={theme.color.icon_light}
              size={theme.icon.size_small}
              onPress={() => {
                confirmActionOnSession(props.onClose);
              }}
            />
          </AddDeviceDialog.StickyHeader>
        );
      case views.bridgeRequired:
        return (
          <AddDeviceDialog.StickyHeader>
            <BackButton onPress={setDriverView}>{i18n.messageFormatter('pair.brands')}</BackButton>
            <AddDeviceDialog.Close
              url={iconCloseThin}
              color={theme.color.icon_light}
              size={theme.icon.size_small}
              onPress={() => {
                confirmActionOnSession(props.onClose);
              }}
            />
          </AddDeviceDialog.StickyHeader>
        );
      case views.matter:
        return (
          <AddDeviceDialog.StickyHeader>
            <BackButton onPress={setDriverView}>{i18n.messageFormatter('pair.brands')}</BackButton>
            <AddDeviceDialog.Close
              url={iconCloseThin}
              color={theme.color.icon_light}
              size={theme.icon.size_small}
              onPress={() => {
                confirmActionOnSession(props.onClose);
              }}
            />
          </AddDeviceDialog.StickyHeader>
        );
      case views.pair:
        return (
          <AddDeviceDialog.StickyHeader data-view-id="pair">
            <BackButton
              onPress={() => {
                confirmActionOnSession(setDriverView);
              }}
            >
              {view.args.appInfo?.appName}
            </BackButton>
            <AddDeviceDialog.Close
              url={iconCloseThin}
              color={theme.color.icon_light}
              size={theme.icon.size_small}
              onPress={() => {
                confirmActionOnSession(props.onClose);
              }}
            />
          </AddDeviceDialog.StickyHeader>
        );
      default:
        return null;
    }
  }

  const isSmallView =
    view.id === views.connect ||
    view.id === views.bridgeRequired ||
    view.id === views.pair ||
    view.id === views.matter;

  return (
    <DialogShiftLock
      layoutId={view.id}
      isOpen
      shouldCloseOnOverlayInteract={() => {
        confirmActionOnSession(props.onClose);
      }}
      onClose={props.onClose}
    >
      <AddDeviceDialog.Wrapper data-is-small-view={isSmallView}>
        <Scroll flex>
          <AddDeviceDialog.Root data-is-small-view={isSmallView}>
            {getHeader()}

            {getView()}
          </AddDeviceDialog.Root>
        </Scroll>
      </AddDeviceDialog.Wrapper>
    </DialogShiftLock>
  );
}

AddDeviceDialog.Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1 800px;
  overflow: hidden;
  width: 780px;

  &[data-is-small-view='true'] {
    width: 420px;
    flex: 1 1 800px;
  }
`;

AddDeviceDialog.Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 0 0 auto;
  margin-bottom: ${su(3)};
`;

AddDeviceDialog.StickyHeader = styled.div`
  position: sticky;
  top: 0;
  z-index: 2;
  background: ${theme.color.component};
  padding: ${su(2, 5)};
  margin-left: ${su(-5)};
  margin-right: ${su(-5)};
  margin-top: ${su(-4)};
  border-top-left-radius: ${theme.borderRadius.default};
  border-top-right-radius: ${theme.borderRadius.default};

  ${isDarkMode} {
    &[data-view-id='pair'] {
      background: ${theme.color.black};
    }
  }
`;

AddDeviceDialog.Close = styled(IconButton)`
  position: absolute;
  right: ${su(2)};
  top: ${su(2)};
  padding: ${su(1)};
  z-index: 5;

  &:hover {
    transform: scale(var(--scale, ${theme.icon.scale_default}));

    ${Icon.S.Root} {
      background: ${theme.icon.color_light_hover};
    }
  }
`;

AddDeviceDialog.Root = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${su(4, 5)};

  &[data-is-small-view='true'] {
    padding: ${su(0)};

    ${AddDeviceDialog.StickyHeader} {
      padding: ${su(2, 2)};
      margin: 0;
    }
  }

  ${isDarkMode} {
    ${PairingFrame.AbsoluteSpinner} {
      background: ${theme.color.black};
    }

    ${PairingFrame.IFrameWrapper} {
      background: ${theme.color.mono_000};
    }

    ${PairingFrame.IFrame} {
      filter: ${theme.filter.darkMode};
    }
  }
`;
