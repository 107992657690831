import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { useTextField, mergeProps } from 'react-aria';

import { stopPressPropagation } from '../../lib/dom/stopPressPropagation';
import { mergeRefs } from '../../lib/mergeRefs';

import { theme } from '../../theme/theme';
import { input } from '../../theme/Global';
import { scrollbarDark } from '../../theme/elements/scrollbars';

import { Hint } from './Hint';

export const TextareaField = React.forwardRef((props, ref) => {
  const { ref: registerRef, ...registerProps } =
    props.register?.(props.name, {
      required: props.required,
      minLength: props.minLength,
      pattern: props.pattern ? new RegExp(props.pattern) : undefined,
    }) ?? {};

  const { autoFocus, ...rest } = props;
  const textFieldRef = useRef();
  const textField = useTextField(
    {
      inputElementType: 'textarea',
      inputMode: 'none',
      ...rest,
    },
    textFieldRef
  );

  const Root =
    props.orientation === 'vertical' ? TextareaField.RootVertical : TextareaField.RootHorizontal;

  useEffect(() => {
    if (autoFocus === true) {
      setTimeout(() => {
        textFieldRef.current.focus();
      }, 0);
    }
  }, [autoFocus]);

  const rows = props.rows ?? '2';

  return (
    <TextareaField.Root as={Root} className={props.className}>
      <TextareaField.Header>
        {props.label != null && (
          <TextareaField.Label {...textField.labelProps} title={props.label}>
            {props.label}
          </TextareaField.Label>
        )}

        {props.hint && <Hint hint={props.hint} />}
      </TextareaField.Header>

      <TextareaField.Input
        {...mergeProps(stopPressPropagation, textField.inputProps, registerProps)}
        ref={mergeRefs([textFieldRef, registerRef, ref])}
        style={{
          '--rows': rows,
        }}
        data-has-error={props.error != null}
        rows={rows}
      />

      {props.children}
    </TextareaField.Root>
  );
});

TextareaField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  hint: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  minLength: PropTypes.number,
  pattern: PropTypes.string,
  autoFocus: PropTypes.bool,
  defaultValue: PropTypes.any,
  error: PropTypes.object,
  register: PropTypes.func,
  orientation: PropTypes.string,
  rows: PropTypes.number,
};

TextareaField.Root = styled.div`
  display: flex;
  position: relative;
`;

TextareaField.Label = styled.label`
  display: inline;
  min-width: 0;
  margin-bottom: 0;
`;

TextareaField.Header = styled.div`
  display: block;

  & :not(:first-child) {
    margin-left: 5px;
  }
`;

TextareaField.Input = styled.textarea`
  ${input};
  ${scrollbarDark};
  position: relative;
  display: inline-block;
  padding: 8px;
  outline: 0;
  resize: none;
  align-self: flex-start;
  scroll-padding: 8px 0;

  &::placeholder {
    color: ${theme.color.mono_300};
  }
`;

TextareaField.RootHorizontal = styled(TextareaField.Root)`
  align-items: center;
  justify-content: space-between;

  ${TextareaField.Label} {
    flex: 0 1 50%;
    font-weight: ${theme.fontWeight.regular};
  }

  ${TextareaField.Input} {
    flex: 0 0 50%;
  }
`;

TextareaField.RootVertical = styled(TextareaField.Root)`
  flex-direction: column;
  align-items: flex-start;

  ${TextareaField.Header} {
    color: ${theme.color.text_light};
    margin-bottom: 5px;
    font-size: ${theme.fontSize.small};
    line-height: 24px;
  }

  ${TextareaField.Input} {
    width: 100%;
  }
`;
