import React from 'react';
import styled from '@emotion/styled';
import { mergeProps } from 'react-aria';

import { theme } from '../../theme/theme';

import { NavLink } from 'react-router-dom';
import { Icon } from './Icon';
import { Tooltip } from './tooltip/Tooltip';

export function IconNavLink(props) {
  const { url, alt, to, tooltip, dynamic, exact, isActive } = props;

  return (
    <Tooltip
      placement="right"
      offset={5}
      anchorPointer={true}
      renderTrigger={(triggerRef, triggerProps) => {
        return (
          <IconNavLink.Trigger
            {...mergeProps(triggerProps, {
              onMouseEnter: () => dynamic?.preload(),
              onPointerEnter: () => dynamic?.preload(),
            })}
            innerRef={triggerRef}
            exact={exact}
            to={to}
            isActive={isActive}
          >
            <Icon size="30px" url={url} alt={alt} color={theme.color.icon_light} display="block" />
          </IconNavLink.Trigger>
        );
      }}
    >
      <IconNavLink.Tooltip>{tooltip}</IconNavLink.Tooltip>
    </Tooltip>
  );
}

IconNavLink.Trigger = styled(NavLink)`
  display: block;
  justify-content: center;
  padding: 10px;
  margin-bottom: 10px;
  transition: all ${theme.curve.fastIn} ${theme.duration.fast};
  outline: 0;

  ${Icon.S.Root} {
    display: block;
    transition: all ${theme.curve.fastIn} ${theme.duration.fast};
  }

  &.active {
    opacity: 1;

    ${Icon.S.Root} {
      background-color: ${theme.color.highlight};
    }

    &:hover {
      ${Icon.S.Root} {
        background-color: ${theme.color.highlight};
      }
    }
  }

  &:hover {
    ${Icon.S.Root} {
      transform: scale(1.133333); // strange values to get rounded result
      background-color: ${theme.color.icon_light_hover};
    }
  }

  &:hover:active {
    ${Icon.S.Root} {
      transform: scale(1);
    }
  }

  &:last-child {
    margin-top: auto;
    margin-bottom: 0;
  }
`;

IconNavLink.Tooltip = styled.div`
  padding: 10px 20px;
  line-height: 20px;
`;
