import { Suspense } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import styled from '@emotion/styled';

import { HomeyError } from './lib/InternalError';

import { useLocale } from 'react-aria';
import { AuthStore, useAuth } from './store/AuthStore';
import { useHomey } from './store/HomeyStore';
import { useI18n } from './hooks/useI18nFormatters';

import { theme } from './theme/theme';

import { SolidButton } from './components/buttons/SolidButton';
import { SpinnerLoading } from './components/common/SpinnerLoading';
import { CenteredMessage } from './components/common/CenteredMessage';

import { LoginPage } from './containers/LoginPage';
import { InvitePage } from './containers/InvitePage';
import {
  HomePage,
  DevicesPage,
  FlowsPage,
  InsightsPage,
  ScriptPage,
  LogicPage,
  SettingsPage,
} from './containers/dynamic';
import { TestPage } from './containers/TestPage';
import { UpdateRebootPage } from './containers/UpdateRebootPage';

import { DragLayer } from './components/dnd/DragLayer';
import { DialogPrompt } from './components/overlay/DialogPrompt';
import { Toasts } from './ToastManager';
import { ErrorBoundary } from './components/ErrorBoundary';

import { Layout } from './Layout';

export function App() {
  const { locale, direction } = useLocale();
  const { isRebooting, isUpdating } = useHomey();

  return (
    <ErrorBoundary>
      <Switch>
        <Route path="/login">
          <LoginPage />
        </Route>

        <Route path="/">
          <App.Root lang={locale} dir={direction}>
            {(() => {
              if (isUpdating === true || isRebooting === true) {
                return <UpdateRebootPage />;
              }

              return (
                <Switch>
                  <Route
                    path="/invite"
                    render={() => {
                      return (
                        <AuthenticatedRoute>
                          <InvitePage />
                        </AuthenticatedRoute>
                      );
                    }}
                  />

                  <Route
                    path="/homeys/:homeyId/devices"
                    render={() => (
                      <HomeyRoute>
                        <Suspense fallback={null}>
                          <DevicesPage />
                        </Suspense>
                      </HomeyRoute>
                    )}
                  />
                  <Route path="/devices" render={() => <HomeyRoute redirect />} />

                  <Route
                    path="/homeys/:homeyId/flows"
                    render={() => (
                      <HomeyRoute>
                        <Suspense fallback={null}>
                          <FlowsPage />
                        </Suspense>
                      </HomeyRoute>
                    )}
                  />
                  <Route path="/flows" render={() => <HomeyRoute redirect />} />

                  <Route
                    path="/homeys/:homeyId/insights"
                    render={() => (
                      <HomeyRoute>
                        <Suspense fallback={null}>
                          <InsightsPage />
                        </Suspense>
                      </HomeyRoute>
                    )}
                  />
                  <Route path="/insights" render={() => <HomeyRoute redirect />} />

                  <Route
                    path="/homeys/:homeyId/scripts"
                    render={() => (
                      <HomeyRoute>
                        <Suspense fallback={null}>
                          <ScriptPage />
                        </Suspense>
                      </HomeyRoute>
                    )}
                  />
                  <Route path="/scripts" render={() => <HomeyRoute redirect />} />
                  <Route path="/script" render={() => <Redirect to="/scripts" />} />

                  <Route
                    path="/homeys/:homeyId/logic"
                    render={() => (
                      <HomeyRoute>
                        <Suspense fallback={null}>
                          <LogicPage />
                        </Suspense>
                      </HomeyRoute>
                    )}
                  />
                  <Route path="/logic" render={() => <HomeyRoute redirect />} />

                  <Route
                    path="/homeys/:homeyId/settings"
                    render={() => (
                      <HomeyRoute>
                        <Suspense fallback={null}>
                          <SettingsPage />
                        </Suspense>
                      </HomeyRoute>
                    )}
                  />
                  <Route path="/settings" render={() => <HomeyRoute redirect />} />

                  <Route
                    path="/homeys/:homeyId/test"
                    render={() => (
                      <HomeyRoute>
                        <Suspense fallback={null}>
                          <TestPage />
                        </Suspense>
                      </HomeyRoute>
                    )}
                  />
                  <Route path="/test" render={() => <HomeyRoute redirect />} />

                  <Route
                    path="/homeys/:homeyId"
                    render={() => (
                      <HomeyRoute>
                        <Suspense fallback={null}>
                          <HomePage />
                        </Suspense>
                      </HomeyRoute>
                    )}
                  />
                  <Route exact path="/" render={() => <HomeyRoute redirect />} />

                  <Route
                    path="*"
                    render={() => {
                      return <NoMatch />;
                    }}
                  />
                </Switch>
              );
            })()}
          </App.Root>
        </Route>
      </Switch>

      {/* Renders some dragged components in a fixed layer. */}
      <DragLayer />

      {/* Prompt for confirmation. */}
      <DialogPrompt />

      <Toasts />
    </ErrorBoundary>
  );
}

App.Root = styled.div`
  position: relative;
  display: flex;
  align-items: stretch;
  position: relative;
  min-width: 720px;
  max-width: 100vw;
  height: 100%;
  max-height: 100vh;
  overflow: hidden;
`;

function AuthenticatedRoute(props) {
  const { i18n } = useI18n();

  const location = useLocation();

  const auth = useAuth();

  // The error check is temporary until the whole auth flow has been redone. We cant render the
  // error on the invite page because people might have no homeys and should still be able to
  // accept invites.
  if (auth.error != null && !(auth.error instanceof HomeyError)) {
    return renderAuthError({ auth, i18n });
  }

  // No user and resolved redirect to login.
  if (auth.user == null && auth.resolved === true) {
    window.location.href = AuthStore.getLoginUrl(location);
    return null;
  }

  if (auth.loading === true || auth.resolved === false) {
    return (
      <CenteredMessage
        icon={<SpinnerLoading color={theme.color.text_light} size={40} />}
        subtitleColor={theme.color.text_light}
        subtitle={i18n.messageFormatter(`status.loading`)}
      />
    );
  }

  if (auth.user != null) {
    return props.children;
  }

  // No case here should not be possible.
  console.warn('Missing implementation for: ', { auth });

  return (
    <CenteredMessage
      icon={<SpinnerLoading color={theme.color.text_light} size={40} />}
      subtitleColor={theme.color.text_light}
      subtitle={i18n.messageFormatter(`status.loading`)}
    />
  );
}

function HomeyRoute(props) {
  const { i18n } = useI18n();

  const location = useLocation();

  const auth = useAuth();
  const homey = useHomey();

  if (auth.error != null) {
    return renderAuthError({ auth, homey, i18n });
  }

  if (homey.error != null) {
    return renderHomeyError({ auth, homey, i18n });
  }

  // No user and resolved redirect to login.
  if (auth.user == null && auth.resolved === true) {
    window.location.href = AuthStore.getLoginUrl(location);
    return null;
  }

  // User and visited non homeyId path.
  if (auth.user != null && auth.current != null && props.redirect) {
    return (
      <Redirect
        to={`/homeys/${auth.current.id}${location.pathname !== '/' ? location.pathname : ''}`}
      />
    );
  }

  // After authenticate with homey is done homey.current is set to the auth.current value. So this
  // is the moment we are done loading the current api or we can show the retry button if the homey
  // is offline.
  if (
    auth.user != null &&
    auth.current != null &&
    homey.current != null &&
    auth.current === homey.current
  ) {
    if (homey.online === false) {
      return renderHomeyOffline({ auth, homey, i18n });
    }

    return props.children;
  }

  if (auth.loading || homey.loading || auth.resolved === false) {
    return <LoadingPage message={i18n.messageFormatter(`status.loading`)} />;
  }

  // No case here should not be possible.
  console.warn('Missing implementation for: ', { auth, homey });

  return <LoadingPage message={i18n.messageFormatter(`status.loading`)} />;
}

export function LoadingPage(props) {
  return (
    <Layout
      content={
        <CenteredMessage
          icon={<SpinnerLoading color={theme.color.text_light} size={40} />}
          subtitleColor={theme.color.text_light}
          subtitle={props.message}
        />
      }
    />
  );
}

function NoMatch() {
  const location = useLocation();

  return (
    <Layout
      content={
        <CenteredMessage title="Unknown route" subtitle={`No match for ${location.pathname}`} />
      }
    />
  );
}

function renderHomeyError({ auth, homey, i18n }) {
  console.error(homey.error);

  // homey-core
  if (homey.error.cause?.error?.includes('User Is Disabled')) {
    return renderHomeyUserDisabled({ auth, homey, i18n });
  }
  // homey-client
  if (homey.error.cause?.error?.includes('user_disabled')) {
    return renderHomeyUserDisabled({ auth, homey, i18n });
  }

  switch (homey.error.cause) {
    case 'homey_offline':
      return renderHomeyOffline({ auth, homey, i18n });
    default:
      return renderHomeyRetry({ auth, homey, i18n });
  }
}

function renderAuthError({ auth, homey, i18n }) {
  console.error(auth.error);
  switch (auth.error.constructor) {
    case HomeyError.NotFound:
      return renderHomeyNotFound({ auth, homey, i18n });
    default:
      break;
  }

  switch (auth.error.cause) {
    default:
      return renderImplementError({ auth, homey, i18n });
  }
}

function renderHomeyUserDisabled({ auth, homey, i18n }) {
  return (
    <Layout
      content={
        <CenteredMessage
          title={i18n.messageFormatter('homeyStatus.userdisabled.title')}
          subtitle={i18n.messageFormatter('homeyStatus.userdisabled.subtitle')}
        />
      }
    />
  );
}

function renderHomeyNotFound({ auth, homey, i18n }) {
  if (auth.homeys?.length === 0) {
    return (
      <Layout
        content={
          <CenteredMessage
            title={i18n.messageFormatter('homeyStatus.nohomey.title')}
            subtitle={i18n.messageFormatter('homeyStatus.nohomey.subtitle')}
          />
        }
      />
    );
  }

  return (
    <Layout
      content={
        <CenteredMessage
          title={i18n.messageFormatter('homeyStatus.notfound.title')}
          subtitle={i18n.messageFormatter('homeyStatus.notfound.subtitle')}
        />
      }
    />
  );
}

function renderHomeyOffline({ auth, homey, i18n }) {
  return (
    <Layout
      content={
        <CenteredMessage
          title={i18n.messageFormatter('homeyStatus.offline.title')}
          subtitle={i18n.messageFormatter('homeyStatus.offline.subtitle')}
          action={
            <SolidButton
              styleColor="green"
              isDisabled={homey.loading === true}
              onPress={() => {
                homey.retry();
              }}
            >
              {i18n.messageFormatter('homeyStatus.offline.button')}
            </SolidButton>
          }
        />
      }
    />
  );
}

function renderHomeyRetry({ auth, homey, i18n }) {
  return (
    <Layout
      content={
        <CenteredMessage
          title="Error"
          subtitle="Unknown error occurred"
          action={
            <SolidButton
              styleColor="green"
              isDisabled={homey.loading === true}
              onPress={() => {
                homey.retry();
              }}
            >
              {i18n.messageFormatter('common.retry')}
            </SolidButton>
          }
        />
      }
    />
  );
}

function renderImplementError({ auth, homey }) {
  let message = ' ';

  if (auth.error) {
    console.error(auth.error);
    if (auth.error.message) {
      message = ` : ${auth.error.message}`;
    }
  }

  if (homey.error) {
    console.error(homey.error);
    if (homey.error.message) {
      message = ` : ${homey.error.message}`;
    }
  }

  return (
    <Layout
      content={<CenteredMessage title="Error" subtitle={`Unknown error occurred${message}`} />}
    />
  );
}
