import React, { useEffect, useRef, useContext, forwardRef, useState } from 'react';
import styled from '@emotion/styled';

import { mergeProps, useFocusRing } from 'react-aria';

import { mergeRefs } from '../../lib/mergeRefs';

import { useForkRef } from '../../hooks/useForkRef';
import { useTreeItemDrag } from './useTreeItemDrag';
import { useTreeItemDrop } from './useTreeItemDrop';

import { theme } from '../../theme/theme';
import { when } from '../../theme/functions/when';

import TreeViewContext from './TreeViewContext';

import { SubNavItem } from '../sub-navigation/SubNavItem';
import { ContextMenu } from '../common/context-menu/ContextMenu';
import { ContextMenuButton } from '../common/context-menu/ContextMenuButton';

import { iconDot } from '../../theme/icons/interface/dot';
import { iconTriangleRight } from '../../theme/icons/interface/triangle-right';

export const TreeItem = forwardRef(function (props, ref) {
  const treeContext = useContext(TreeViewContext);

  const nodeRef = useRef(null);
  const contentRef = useRef(null);
  const handleRef = useForkRef(nodeRef, ref);

  const expandable = Boolean(
    Array.isArray(props.children) ? props.children.length : props.children
  );

  const isExpanded = treeContext.isExpanded ? treeContext.isExpanded(props.nodeId) : false;
  const isTabbable = treeContext.isTabbable ? treeContext.isTabbable(props.nodeId) : false;
  const isSelected = treeContext.isSelected ? treeContext.isSelected(props.nodeId) : false;

  function handleClick(event) {
    // Toggle expansion if the click target is >= level 1 and expandable
    if (expandable && props.level > 0) {
      treeContext.toggleExpansion(event, props.nodeId);
    }

    treeContext.selectNode(event, props.nodeId, props.nodeData);

    if (props.onClick) {
      props.onClick(event);
    }
  }

  // function handleMouseDown(event) {
  //   if (event.shiftKey || event.ctrlKey || event.metaKey) {
  //     event.preventDefault();
  //   }

  //   if (props.onMouseDown) {
  //     props.onMouseDown(event);
  //   }
  // }

  useEffect(() => {
    if (treeContext.addNodeToNodeMap != null) {
      const addNodeToNodeMap = treeContext.addNodeToNodeMap;
      const childIds = [];
      React.Children.forEach(props.children, (child) => {
        if (React.isValidElement(child) && child.props.nodeId) {
          childIds.push(child.props.nodeId);
        }
      });
      addNodeToNodeMap(props.nodeId, childIds);
    }
  }, [props.children, props.nodeId, treeContext.addNodeToNodeMap]);

  useEffect(() => {
    if (treeContext.removeNodeFromNodeMap != null) {
      const removeNodeFromNodeMap = treeContext.removeNodeFromNodeMap;

      return () => {
        removeNodeFromNodeMap(props.nodeId);
      };
    }
    return undefined;
  }, [props.nodeId, treeContext.removeNodeFromNodeMap]);

  let iconUrl = props.iconUrl;

  if (!iconUrl) {
    if (expandable) {
      if (!isExpanded) {
        iconUrl = iconTriangleRight;
      }

      if (props.level === 0) {
        iconUrl = iconDot;
      }

      if (!iconUrl) {
        // can set something default here
        iconUrl = iconTriangleRight;
      }
    }
  }

  const { dragRef } = useTreeItemDrag({
    type: props.dragType,
    nodeId: props.nodeId,
    nodeType: props.nodeType,
  });
  const { isDragOver, canDrop, dropRef } = useTreeItemDrop({
    type: props.dragType,
    nodeId: props.nodeId,
    nodeType: props.nodeType,
    treeContext,
  });

  const ContextMenuContent = props.contextMenuContent;
  const [isRenaming, setIsRenaming] = useState(props.defaultIsRenaming ?? false);
  const focusRing = useFocusRing();

  function renderRenaming() {
    return (
      <SubNavItem.NameTextField
        aria-label="Item name"
        defaultValue={props.label}
        selectDefaultValue={true}
        onSaveRequest={(value) => {
          props.onSaveNameRequest(value);
          setIsRenaming(false);
        }}
        onCancelRequest={() => {
          props.onCancelNameRequest?.();
          setIsRenaming(false);
        }}
      />
    );
  }

  return (
    <TreeItem.Root
      style={{
        '--level': props.level,
      }}
      ref={handleRef}
      className={props.className}
      role="treeitem"
      aria-expanded={expandable ? isExpanded : null}
      aria-selected={isSelected === true}
      aria-disabled={null} // todo
      tabIndex={isTabbable ? 0 : -1}
    >
      <ContextMenu
        content={
          ContextMenuContent ? (
            <ContextMenuContent
              {...props.contextMenuContentProps}
              nodeId={props.nodeId}
              onRenameRequest={() => {
                setIsRenaming(true);
              }}
            />
          ) : null
        }
      >
        {({ isOpen, onContextMenu }) => {
          return (
            <SubNavItem.Root
              {...mergeProps(focusRing.focusProps)}
              ref={mergeRefs([dropRef, dragRef, contentRef])}
              as="div"
              title={props.title}
              data-is-selected={isSelected === true}
              data-is-renaming={isRenaming}
              data-is-focus-visible={focusRing.isFocusVisible}
              data-show-disabled-style={props.isDisabled}
              data-is-drag-over={isDragOver}
              data-can-drop={canDrop}
              data-is-context-menu-open={isOpen}
              onClick={handleClick}
              //onMouseDown={handleMouseDown}
              onContextMenu={onContextMenu}
            >
              <SubNavItem.Link isDisabled={isRenaming} href={props.href}>
                <TreeItem.Transform isExpanded={expandable ? isExpanded : null}>
                  {props.iconOverride != null ? (
                    props.iconOverride
                  ) : (
                    <SubNavItem.Icon url={iconUrl} size={theme.icon.size_small} />
                  )}
                </TreeItem.Transform>

                {isRenaming ? renderRenaming() : <SubNavItem.Name>{props.label}</SubNavItem.Name>}
              </SubNavItem.Link>

              {isRenaming === false && (
                <ContextMenuButton isOpen={isOpen} onPress={onContextMenu} />
              )}
            </SubNavItem.Root>
          );
        }}
      </ContextMenu>
      {props.children && <ul role="group">{isExpanded && props.children}</ul>}
    </TreeItem.Root>
  );
});

TreeItem.Root = styled.li`
  outline: 0;
`;

TreeItem.Transform = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform ${theme.transition.micro_partial};

  ${when((props) => props.isExpanded === true)} {
    transform: rotate(90deg);
  }
`;

// function dragItemRender() {
//   return (
//     <sc.ItemDetails css={sc_css.dragItem} isSelected={selected === true}>
//       <sc.ItemIconWrapper>
//         <sc.ItemIcon size={20} mask={mask} color={theme.color.icon_light} isExpanded={false} />
//       </sc.ItemIconWrapper>
//       <sc.ItemLabel>{label}</sc.ItemLabel>
//     </sc.ItemDetails>
//   );
// }
