import React, { useState, useEffect, useRef, useMemo } from 'react';
import styled from '@emotion/styled';
import debounce from 'lodash.debounce';
import { v4 as uuid } from 'uuid';

import {
  useListBox,
  useOption,
  useSelect,
  useOverlay,
  useTextField,
  HiddenSelect,
  DismissButton,
  mergeProps,
  useOverlayPosition,
} from 'react-aria';
import { useOverlayTriggerState, useSelectState, Item } from 'react-stately';

import { ToastManager } from '../../../ToastManager';
import { ResourceUtils } from '../../../store/ResourceUtils';

import { useI18n } from '../../../hooks/useI18nFormatters';
import { useArgumentValues } from './argumentHooks';
import { useApi } from '../../../store/HomeyStore';
import { useNodeArgumentContext } from '../view-advanced-flow/card/NodeArgumentsContext';

import { theme } from '../../../theme/theme';
import { containers } from '../../../theme/elements/containers';
import { argInput } from './argInput';

import { Scroll } from '../../../components/common/Scroll';
import { Icon } from '../../../components/common/Icon';
import { Image } from '../../../components/common/Image';
import { ArgumentBase, Argument } from './Argument';

import { iconMagnifyingGlass } from '../../../theme/icons/interface/magnifying-glass/magnifying-glass';
import { useVariable } from '../../../store/logic/useLogic';
import { useUser } from '../../../store/users/useUsers';
import { useFlow } from '../../../store/flow/useFlows';
import { useAdvancedFlow } from '../../../store/advanced-flow/useAdvancedFlows';

/**
 * ArgumentAutocomplete
 * https://apps.developer.homey.app/the-basics/flow/arguments#autocomplete
 */
export function ArgumentAutocomplete(props) {
  const { storeValue, localValue, setLocalValue } = useArgumentValues({
    props,
  });

  const triggerState = useOverlayTriggerState({
    defaultOpen: props.isFirstArgument && storeValue === undefined,
  });

  let renderValue = null;

  if (localValue != null) {
    switch (props.argument.items) {
      case 'variable':
        renderValue = <ArgumentAutocompleteVariable value={localValue} />;
        break;
      case 'user':
        renderValue = <ArgumentAutocompleteUser value={localValue} />;
        break;
      case 'flow_or_advanced_flow':
        if (localValue.type === 'advanced') {
          renderValue = <ArgumentAutocompleteAdvancedFlow value={localValue} />;
        } else {
          renderValue = <ArgumentAutocompleteFlow value={localValue} />;
        }
        break;
      default:
        renderValue = localValue.name;
        break;
    }
  } else {
    renderValue = props.argumentTypeText;
  }

  const { isInvalid, setInvalid } = useNodeArgumentContext({
    key: props.argumentKey,
    onInvalidRequest() {
      return onInvalidRequest(storeValue);
    },
  });

  function onInvalidRequest(value) {
    let isInvalid = false;

    if (value == null && props.argument.required !== false) {
      isInvalid = true;
    } else if (value === '') {
      isInvalid = true;
    }

    return isInvalid;
  }

  function checkInvalid(value, options) {
    // isInvalid has been touched
    if (isInvalid != null) {
      setInvalid(onInvalidRequest(value), options);
    }
  }

  function updateCard(value) {
    if (storeValue !== value) {
      const nextValue = value != null ? value : undefined;

      props.onUpdate?.({
        argumentKey: props.argumentKey,
        value: nextValue,
      });
    }
  }

  function handleChange(value) {
    triggerState.close();

    const nextValue = value;
    setLocalValue(nextValue);
    checkInvalid(nextValue, { checkNode: false });

    updateCard(nextValue);
  }

  function handleSaveRequest() {
    triggerState.close();
    updateCard(localValue);
    checkInvalid(localValue, { checkNode: true });
  }

  function handleCloseRequest() {
    handleSaveRequest();
  }

  function handleCancelRequest() {
    triggerState.close();
    setLocalValue(storeValue);
    checkInvalid(storeValue, { checkNode: true });
  }

  return (
    <Argument
      cardContainerRef={props.cardContainerRef}
      triggerState={triggerState}
      isTriggerDisabled={props.isDisabled}
      isTriggerDisabledStyle={props.isDisabledStyle}
      onCloseRequest={handleCloseRequest}
      onCancelRequest={handleCancelRequest}
      renderTrigger={(triggerRef, triggerProps) => {
        return (
          <ArgumentBase
            {...triggerProps}
            ref={triggerRef}
            data-is-invalid={isInvalid}
            data-is-empty={localValue == null}
          >
            {localValue?.image && (
              <sc.ArgumentImage size={theme.icon.size_small} url={localValue.image} />
            )}
            {localValue?.icon && (
              <ArgumentIcon size={theme.icon.size_small} url={localValue.icon} />
            )}
            {renderValue}
          </ArgumentBase>
        );
      }}
      renderOverlay={() => {
        return (
          <AutocompleteInput
            card={props.card}
            cardType={props.cardType}
            argumentKey={props.argumentKey}
            value={localValue}
            args={props.data.args}
            label={props.argument.title}
            placeholder={props.argument.placeholder ?? ''}
            onChange={handleChange}
            onSaveRequest={handleSaveRequest}
            onInteractOutside={(element) => {
              triggerState.close();
            }}
          />
        );
      }}
    />
  );
}

function ArgumentAutocompleteVariable({ value }) {
  const { variable } = useVariable({ variableId: value.id });

  let display = value.name;
  if (variable) {
    display = variable.name;
  }
  return display;
}

function ArgumentAutocompleteUser({ value }) {
  const { user } = useUser({ userId: value.id });

  let display = value.name;
  if (user) {
    display = user.name;
  }
  return display;
}

function ArgumentAutocompleteFlow({ value }) {
  const { flow } = useFlow({ flowId: value.id });

  let display = value.name;
  if (flow) {
    display = flow.name;
  }
  return display;
}

function ArgumentAutocompleteAdvancedFlow({ value }) {
  const { advancedFlow } = useAdvancedFlow({ advancedFlowId: value.id });

  let display = value.name;
  if (advancedFlow) {
    display = advancedFlow.name;
  }
  return display;
}

function AutocompleteInput(props) {
  const { i18n } = useI18n();
  const { api } = useApi();

  const selectRef = useRef();
  const instanceRef = useRef({
    pendingId: null,
  });

  const label = props.label ?? i18n.messageFormatter('flow.argument.autocomplete.title');

  const [query, setQuery] = useState(props.value?.name ?? '');
  const [sourceItemsList, setSourceItemsList] = useState([]);

  const handleQueryChange = useMemo(() => {
    return debounce((value) => {
      setQuery(value);
    }, 200);
  }, []);

  const children = (sourceItem) => {
    return (
      <Item
        key={sourceItem.key}
        value={sourceItem}
        textValue={sourceItem.entry.name}
        children={null}
      />
    );
  };

  useEffect(() => {
    async function fetch() {
      const id = (instanceRef.current.pendingId = uuid());
      try {
        const results = await api?.flow.getFlowCardAutocomplete({
          uri: ResourceUtils.getOwnerUri(props.card),
          id: props.card.id,
          name: props.argumentKey,
          query: query,
          type: `flowcard${props.cardType}`,
          args: props.args,
        });

        const keyedItemsList = results?.map((item, index) => {
          return {
            key: index,
            entry: item,
          };
        });

        if (instanceRef.current.pendingId !== id) return;
        setSourceItemsList(keyedItemsList ?? []);
      } catch (error) {
        if (instanceRef.current.pendingId !== id) return;
        ToastManager.handleError(error);
      }
    }

    fetch();
  }, [query, api, props.card, props.cardType, props.argumentKey, props.args]);

  useEffect(() => {
    if (selectRef.current) {
      selectRef.current.value = props.value?.name ?? '';
    }
  }, [props.value]);

  useEffect(() => {
    requestAnimationFrame(() => {
      selectRef.current?.focus();
    });
  }, []);

  const sharedProps = {
    children,
    items: sourceItemsList,
    label: label,
    placeholder: props.placeholder,
    // We can never allow selection on autocomplete since its not a guaranteed unique value. Its also
    // not a set rule for developers to return unique items.
    selectedKey: null,
    onSelectionChange: (key) => {
      const item = sourceItemsList.find((sourceItem) => {
        // This is just the autocomplete results index
        return String(sourceItem.key) === String(key);
      });

      props.onChange(item?.entry ?? null);
    },
  };

  const selectState = useSelectState({ ...sharedProps });
  const select = useSelect({ ...sharedProps, autoFocus: false }, selectState, selectRef);

  const { onBlur, onFocus, onKeyDown, onKeyDownCapture, onPress, onPressStart, ...rest } =
    select.triggerProps;

  const textField = useTextField(
    {
      ...rest,
      placeholder: props.placeholder,
      type: 'text',
      onChange(...args) {
        selectState.open();
        handleQueryChange(...args);
      },
      onFocus() {
        //selectState.open();
      },
      onKeyDown(event) {
        // Consume every key and save on enter.
        if (event.key === 'Enter') {
          event.preventDefault();
          props.onSaveRequest();
        } else if (event.key === 'Delete') {
          event.preventDefault();
          props.onChange(undefined);
        } else if (event.key === 'Escape') {
          // Propagate on escape.
          event.continuePropagation();
        }
      },
      onKeyUp(event) {
        // Consume every key and save on enter.
        if (event.key === 'Enter') {
          event.preventDefault();
        } else if (event.key === 'Delete') {
          event.preventDefault();
        } else if (event.key === 'Escape') {
          // Propagate on escape.
          event.continuePropagation();
        }
      },
    },
    selectRef
  );

  const ref = useRef({});
  ref.current.open = selectState.open;
  useEffect(() => {
    if (sourceItemsList?.length > 0) {
      // Because Overlay animates scaleIn the position of the targetRef is never correct.
      // We try to wait for the animation to be done (theme.duration.fast) and open after.
      const timeout = setTimeout(() => {
        ref.current.open();
      }, 200);

      return function () {
        clearTimeout(timeout);
      };
    }
  }, [sourceItemsList]);

  const containerRef = useRef();

  return (
    <argInput.InputContainer ref={containerRef}>
      <HiddenSelect state={selectState} triggerRef={selectRef} label={label} name={props.name} />
      <argInput.InputLabel {...select.labelProps}>{label}</argInput.InputLabel>
      <argInput.InputRow>
        <argInput.InputWrapper>
          {props.value?.image && <Image size={theme.icon.size_default} url={props.value?.image} />}
          {props.value?.icon && <Icon size={theme.icon.size_default} url={props.value?.icon} />}
          <argInput.Input {...textField.inputProps} ref={selectRef} />
          <argInput.InputIcon url={iconMagnifyingGlass} />
        </argInput.InputWrapper>
      </argInput.InputRow>
      {selectState.isOpen && (
        <ListBoxPopup
          messageFormatter={i18n.messageFormatter}
          menuProps={select.menuProps}
          targetRef={selectRef}
          selectState={selectState}
          onInteractOutside={(element) => {
            if (
              containerRef.current.contains(element) ||
              element.isSameNode(containerRef.current)
            ) {
              return;
            }

            props.onInteractOutside(element);
          }}
        />
      )}
    </argInput.InputContainer>
  );
}

function ListBoxPopup(props) {
  delete props.menuProps.autoFocus;

  const listBoxRef = useRef();
  const listBox = useListBox(
    {
      autoFocus: false,
      shouldFocusOnHover: false,
      disallowEmptySelection: false,
      ...props.menuProps,
    },
    props.selectState,
    listBoxRef
  );

  const overlayRef = useRef();
  const overlay = useOverlay(
    {
      onClose: () => props.selectState.close(),
      shouldCloseOnBlur: false,
      isOpen: props.selectState.isOpen,
      isDismissable: true,
      shouldCloseOnInteractOutside(element) {
        props.onInteractOutside(element);
        return true;
      },
    },
    overlayRef
  );

  const overlayPosition = useOverlayPosition({
    targetRef: props.targetRef,
    overlayRef: overlayRef,
    placement: 'bottom',
    offset: 5,
    crossOffset: 0,
    isOpen: props.selectState.isOpen,
    shouldUpdatePosition: true,
    shouldFlip: true,
    onClose: () => props.selectState.close(),
  });

  const collectionItems = [...props.selectState.collection];

  return (
    <sc.Wrapper {...overlay.overlayProps} {...overlayPosition.overlayProps} ref={overlayRef}>
      <sc.Overlay>
        <DismissButton onDismiss={() => props.selectState.close()} />
        <Scroll>
          <sc.List {...mergeProps(listBox.listBoxProps, props.menuProps)} ref={listBoxRef}>
            {collectionItems.map((collectionItem) => {
              return (
                <Option
                  key={collectionItem.key}
                  collectionItem={collectionItem}
                  state={props.selectState}
                />
              );
            })}

            {collectionItems.length === 0 && (
              <li>{props.messageFormatter('common.emptyResults')}</li>
            )}
          </sc.List>
        </Scroll>
        <DismissButton onDismiss={() => props.selectState.close()} />
      </sc.Overlay>
    </sc.Wrapper>
  );
}

function Option({ collectionItem, state }) {
  const optionRef = useRef();
  const isDisabled = state.disabledKeys.has(collectionItem.key);
  const isSelected = state.selectionManager.isSelected(collectionItem.key);
  const option = useOption(
    {
      key: collectionItem.key,
      isDisabled,
      isSelected,
      shouldSelectOnPressUp: true,
      shouldFocusOnHover: false,
    },
    state,
    optionRef
  );

  const sourceItem = collectionItem.value;
  const hasIcon = sourceItem.entry.image != null || sourceItem.entry.icon != null;

  return (
    <sc.ListItem {...mergeProps(option.optionProps)} ref={optionRef}>
      {hasIcon && (
        <ListItemIconWrapper>
          {sourceItem.entry.image != null && (
            <sc.ListImage size={theme.icon.size_default} url={sourceItem.entry.image} />
          )}
          {sourceItem.entry.icon != null && (
            <Icon size={theme.icon.size_default} url={sourceItem.entry.icon} />
          )}
        </ListItemIconWrapper>
      )}

      <sc.ListItemDetails>
        <sc.ListItemName>{sourceItem.entry.name}</sc.ListItemName>
        <sc.ListItemDescription>{sourceItem.entry.description}</sc.ListItemDescription>
      </sc.ListItemDetails>
    </sc.ListItem>
  );
}

const ArgumentImage = styled(Image)`
  vertical-align: middle;
  margin-right: 4px;
  transform: translateY(-2px);
`;

const ArgumentIcon = styled(Icon)`
  vertical-align: middle;
  margin-right: 4px;
  transform: translateY(-2px);
`;

const ListImage = styled(Image)`
  margin: -5px 0;
  vertical-align: middle;
  display: block;
`;

const Wrapper = styled.div`
  width: 300px;
  min-height: 40px;
`;

const Overlay = styled.div`
  ${containers.card};
  position: relative;
  overflow: hidden;
  flex: 1 1 auto;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: stretch;
  justify-content: stretch;
  max-height: 320px;
`;

const List = styled.ul`
  width: 100%;
  outline: 0;
  padding: 5px;
`;

const ListItem = styled.li`
  display: flex;
  align-items: center;
  outline: 0;
  padding: 10px;
  cursor: pointer;
  border-radius: ${theme.borderRadius.default};

  &:not(:first-of-type) {
    margin-top: 5px;
  }

  &:not(:last-of-type) {
    margin-bottom: 5px;
  }

  &:hover {
    background-color: ${theme.color.background_hover};
    font-weight: ${theme.fontWeight.medium};
  }
`;

const ListItemIconWrapper = styled.div`
  margin-right: 10px;
`;

const ListItemDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const ListItemName = styled.div`
  display: flex;
  align-items: center;
`;

const ListItemDescription = styled.div`
  color: ${theme.color.text_light};
`;

export const sc = {
  Wrapper,
  Overlay,
  List,
  ListItem,
  ListItemIconWrapper,
  ListItemDetails,
  ListItemName,
  ListItemDescription,
  ArgumentImage,
  ListImage,
};
