import { useRef, useState, useLayoutEffect } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { useOverlayPosition, DismissButton, OverlayContainer, FocusScope } from 'react-aria';
import { getFocusableTreeWalker } from '@react-aria/focus';

import { mergeRefs } from '../../lib/mergeRefs';
import { OverlayStackContext } from './OverlayStackContext';
import { OverlayContext } from './OverlayContext';
import { OverlaySource } from './OverlaySource';

import { overlayCloseReasons } from './overlayCloseReasons';

import { useCurrentProps } from '../../hooks/useCurrentProps';
import { useOverlayStackContext } from './OverlayStackContext';
import { useOverlay } from './useOverlay';

import { containers } from '../../theme/elements/containers';
import { theme } from '../../theme/theme';
import { animationFade } from '../../theme/animations/animationFade';
import { animationScalePerspFade } from '../../theme/animations/animationScalePerspFade';

import { AnchorPointer } from '../common/AnchorPointer';

export function Overlay(props) {
  const [isPreventCloseOverride, setPreventCloseOverride] = useState(null);
  const [stackIndex, setStackIndex] = useState(-1);

  const overlayContext = useCurrentProps({ setPreventCloseOverride, stackIndex });

  const overlayRef = useRef();
  const instanceRef = useRef({
    didFocus: false,
    isPreventClose: isPreventCloseOverride,
    onCloseRequest: onCloseRequestMiddleWare,
  });

  function onCloseRequestMiddleWare(reason) {
    if (isPreventCloseOverride) {
      return;
    }

    if (props.onClose != null) {
      // If something wants to respond different to close behavior.
      // E.g. click outside = save or escape = cancel.
      // They are responsible for calling overlayTriggerState.close()
      props.onClose?.(reason);
      return;
    }

    // Stack removal happens in useLayoutEffect cleanup.
    props.overlayTriggerState?.close();
  }

  useLayoutEffect(() => {
    instanceRef.current.onCloseRequest = onCloseRequestMiddleWare;
    instanceRef.current.isPreventClose = isPreventCloseOverride;
  });

  const { context, isRoot } = useOverlayStackContext();

  useLayoutEffect(() => {
    const source = new OverlaySource({
      ref: overlayRef,
      onCloseRequest(reason) {
        instanceRef.current.onCloseRequest(reason);
      },
      getIsPreventClose() {
        return instanceRef.current.isPreventClose;
      },
    });

    const length = context.stack.push(source);
    setStackIndex(length - 1);

    return function () {
      // It might have been removed already onCloseRequest
      // console.log('current:stack', [...context.stack])
      const index = context.stack.findIndex((element) => element === source);

      if (index > -1) {
        context.stack.splice(index, 1);
        // console.log('next:stack', [...context.stack])
      }
    };
  }, [context]);

  const overlay = useOverlay(
    {
      context,
      isOpen: props.overlayTriggerState?.isOpen ?? true,
      isKeyboardDismissDisabled: false,
      onCloseRequest: (reason) => {
        // This one fires for keyboard.
        onCloseRequestMiddleWare(reason);
      },
    },
    overlayRef
  );

  const overlayPosition = useOverlayPosition({
    boundaryElement: document.body,
    containerPadding: 20,
    targetRef: props.targetRef,
    overlayRef: overlayRef,
    placement: props.placement ?? 'bottom',
    offset: props.offset ?? 5,
    crossOffset: props.crossOffset ?? 0,
    isOpen: props.overlayTriggerState?.isOpen ?? true,
    shouldFlip: true,
    shouldUpdatePosition: true,
    onClose() {
      // Happens on scroll.
      // https://github.com/adobe/react-spectrum/blob/bacfedcaa8ffbc8a750cca94c5eca77895db93b9/packages/%40react-aria/overlays/src/useOverlayPosition.ts#L170
      onCloseRequestMiddleWare(Overlay.REASON.SCROLL);
    },
  });

  overlayPosition.overlayProps.style.zIndex = undefined;
  overlayPosition.overlayProps.style['--stack-index'] = stackIndex;

  let [tabIndex, setTabIndex] = useState(undefined);

  // Check if the overlay has a focusable child. If not we make the overlay itself focusable so it
  // can be closed with the keyboard.
  useLayoutEffect(() => {
    if (overlayRef.current) {
      const update = () => {
        // Detect if there are any tabbable elements and update the tabIndex accordingly.
        let walker = getFocusableTreeWalker(overlayRef.current, { tabbable: true });
        setTabIndex(walker.nextNode() ? undefined : 0);
      };

      update();

      // Update when new elements are inserted, or the tabIndex/disabled attribute updates.
      const observer = new MutationObserver(update);
      observer.observe(overlayRef.current, {
        subtree: true,
        childList: true,
        attributes: true,
        attributeFilter: ['tabIndex', 'disabled'],
      });

      return () => {
        observer.disconnect();
      };
    }
  }, []);

  // If autoFocus was enabled and we set the tabIndex to the overlay make ensure we focus it. But
  // only once during the lifetime of this component.
  useLayoutEffect(() => {
    if (props.autoFocus === true && tabIndex != null && instanceRef.current.didFocus === false) {
      instanceRef.current.didFocus = true;
      overlayRef.current.focus();
    }
  }, [props.autoFocus, tabIndex]);

  function wrap(children) {
    if (isRoot === true) {
      return (
        <OverlayStackContext.Provider value={context}>{children}</OverlayStackContext.Provider>
      );
    }

    return children;
  }

  return wrap(
    <OverlayContext.Provider value={overlayContext}>
      <OverlayContainer>
        <FocusScope
          restoreFocus={props.restoreFocus ?? true}
          // If the exit transition is happening we need to be able to focus other elements
          // outside of the scope.
          contain={props.animationRemainProps?.isExiting ? false : props.containFocus ?? true}
          autoFocus={props.autoFocus ?? false}
        >
          <Overlay.Wrapper
            {...props.overlayProps}
            {...overlay.overlayProps}
            {...overlayPosition.overlayProps}
            ref={mergeRefs([overlayRef, props.overlayRef])}
            data-stack-index={stackIndex}
            tabIndex={tabIndex}
          >
            {props.anchorPointer !== false && (
              <AnchorPointer
                {...overlayPosition.arrowProps}
                placement={overlayPosition.placement}
                animationRemainProps={props.animationRemainProps}
              />
            )}

            <Overlay.Root
              animationRemainProps={props.animationRemainProps}
              animationRemainAnimation={props.animationRemainAnimation}
              placement={overlayPosition.placement}
              transformOriginX={overlayPosition.arrowProps.style.left}
              transformOriginY={overlayPosition.arrowProps.style.top}
              style={{
                maxHeight: overlayPosition.overlayProps.style.maxHeight,
              }}
            >
              <DismissButton onDismiss={() => props.overlayTriggerState?.close()} />

              {props.children}

              <DismissButton onDismiss={() => props.overlayTriggerState?.close()} />
            </Overlay.Root>
          </Overlay.Wrapper>
        </FocusScope>
      </OverlayContainer>
    </OverlayContext.Provider>
  );
}

Overlay.REASON = overlayCloseReasons;

Overlay.Wrapper = styled.div`
  outline: 0;
  //display: flex;
  //flex-direction: column;
  //align-items: stretch;
  //justify-content: stretch;

  z-index: calc(${theme.zIndex.overlay} + var(--stack-index, 0));
`;

/**
 * Note the AnchorPointer has the same animation parameters but it's only a fade. We need
 * overflow: hidden on the Root so it resizes with the window.
 */

Overlay.Root = styled.div`
  ${containers.card};
  position: relative;
  display: flex;
  flex-direction: column;
  opacity: 0;

  ${(props) => {
    if (props.animationRemainProps == null) {
      return {
        opacity: 1,
      };
    }

    const { isExiting, duration } = props.animationRemainProps;

    // Issue on Safari if placement is undefined and it's defined on the next render Safari does not
    // seem to allow an update for transform origin.
    if (props.placement == null) {
      return;
    }

    if (props.animationRemainAnimation === 'fade') {
      const animation = isExiting ? animationFade.out : animationFade.in;

      return css`
        animation: ${animation} ${duration} ease-out 1 forwards;
      `;
    }

    const animation = isExiting ? animationScalePerspFade.out : animationScalePerspFade.in;

    const vars = animationScalePerspFade.setupVars(
      props.placement,
      props.transformOriginX,
      props.transformOriginY
    );

    return css`
      ${vars};
      animation: ${animation} ${duration} ease-out 1 forwards;
    `;
  }};
`;
