import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import { theme } from '../../theme/theme';

export function IndicatorString({ capabilities, capabilityId }) {
  const capability = capabilities[capabilityId];
  const [value, setValue] = useState(capability?.value);

  useEffect(() => {
    if (capability != null) {
      const unregister = capability.onChange(({ value }) => {
        setValue(value);
      });

      setValue(capability.value);

      return function () {
        unregister();
      };
    }
  }, [capability]);

  return <IndicatorString.Root>{value}</IndicatorString.Root>;
}

IndicatorString.Root = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  font-size: ${theme.fontSize.tiny};
  font-weight: ${theme.fontWeight.bold};
  -webkit-line-clamp: 3;
  line-clamp: 3;
  max-width: 54px;
  word-break: break-word;
  overflow: hidden;
`;
