import { useMemo } from 'react';

import { promptMessage } from '../../../components/overlay/DialogPrompt';

import { ToastManager } from '../../../ToastManager';

import { useI18n } from '../../../hooks/useI18nFormatters';
import { useZonesTree } from '../../../store/zones/useZones';
import { useContextMenuContext } from '../../../components/common/context-menu/ContextMenuContextProvider';

import { zoneIcons } from '../../../theme/HomeyImages';

import { ContextMenuContent } from '../../../components/common/context-menu/ContextMenuContent';

import { ZoneIconListBox } from './ZoneIconListBox';
import { ZoneParentListBox } from './ZoneParentListBox';
import { DeveloperMenu } from '../../../components/common/context-menu/DeveloperMenu';

import { iconTrashFill } from '../../../theme/icons/interface/trash-fill/trash-fill';
import { theme } from '../../../theme/theme';

export function ZoneContextMenuContent(props) {
  const { i18n } = useI18n();
  const ctx = useContextMenuContext();

  const zones = useZonesTree();
  const filteredZones = useFilteredZones({
    zoneId: props.zone.id,
    zone: props.zone,
    zones,
  });

  function onAction(key) {
    switch (key) {
      case 'rename':
        props.onRenameRequest();
        break;
      case 'create':
        props.onCreateRequest();
        break;
      case 'delete':
        promptMessage({
          message: i18n.messageFormatter('prompt.delete', {
            name: props.zone.name,
          }),
        })
          .then(() => {
            zones.manager
              .deleteZone({ id: props.zone.id })
              .then(() => {})
              .catch((error) => {
                const message = i18n.messageFormatter(`errors.${error.name}`, null, error.name);
                ToastManager.addError({
                  message: message,
                });
              });
          })
          .catch(() => {});
        break;
      default:
        break;
    }
  }

  return (
    <ContextMenuContent.List>
      <ContextMenuContent.ListItem
        label={i18n.messageFormatter('common.rename')}
        onPress={() => {
          onAction('rename');
        }}
      />

      <ContextMenuContent.SubMenu title={i18n.messageFormatter(`zone.editIcon`)}>
        <ZoneIconListBox
          selectionMode="single"
          defaultSelectedKeys={[props.zone.icon]}
          onSelectionChange={([id]) => {
            ctx.onCloseRequest();
            zones.manager
              .updateZone({
                id: props.zone.id,
                zone: { name: props.zone.name, icon: id },
              })
              .then(() => {})
              .catch((error) => {
                ToastManager.handleError(error);
              });
          }}
        >
          {zoneIcons.map((icon) => {
            return <ZoneIconListBox.Item key={icon.id} icon={icon} textValue={icon.id} />;
          })}
        </ZoneIconListBox>
      </ContextMenuContent.SubMenu>

      {zones.tree.root.id !== props.zone.id && (
        <ContextMenuContent.SubMenu title={i18n.messageFormatter(`zone.moveTo`)}>
          <ZoneParentListBox
            selectionMode="single"
            onSelectionChange={([id]) => {
              ctx.onCloseRequest();
              zones.manager
                .updateZone({
                  id: props.zone.id,
                  zone: { name: props.zone.name, parent: id },
                })
                .then(() => {})
                .catch((error) => {
                  ToastManager.handleError(error);
                });
            }}
            selectedKeys={[]}
          >
            {filteredZones.map((zone) => {
              return (
                <ZoneParentListBox.Item
                  key={zone.id}
                  zone={zone}
                  zoneData={zones.byId[zone.id]}
                  textValue={zones.byId[zone.id].name}
                />
              );
            })}
          </ZoneParentListBox>
        </ContextMenuContent.SubMenu>
      )}

      <ContextMenuContent.ListItem
        label={i18n.messageFormatter('zone.newZone')}
        onPress={() => {
          onAction('create');
        }}
      />

      <DeveloperMenu onIdRequest={() => props.zone?.id} onResourceRequest={() => props.zone} />

      {zones.tree.root.id !== props.zone.id && (
        <>
          <ContextMenuContent.Divider />

          <ContextMenuContent.ListItem
            color={theme.color.danger}
            label={i18n.messageFormatter('common.delete')}
            variant="danger"
            icon={iconTrashFill}
            isDisabled={zones.tree?.children[props.zone.id]?.size !== 0}
            onPress={() => {
              onAction('delete');
            }}
          />
        </>
      )}
    </ContextMenuContent.List>
  );
}

function useFilteredZones({ zoneId, zone, zones }) {
  const zoneRef = zone.__ref;

  return useMemo(() => {
    if (!zones.tree) return [];
    if (!zoneId) return zones.tree.list;

    const children = zones.tree.children[zoneId];

    return children
      ? zones.tree.list.filter((zone) => {
          return !children.has(zone.id) && zoneId !== zone.id;
        })
      : [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zoneId, zoneRef, zones.tree]);
}
