import React, { useRef, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import styled from '@emotion/styled';
import { mergeProps, useTextField } from 'react-aria';

import { mergeRefs } from '../../../lib/mergeRefs';

import { useI18n } from '../../../hooks/useI18nFormatters';

import { theme } from '../../../theme/theme';
import { su } from '../../../theme/functions/su';
import { input } from '../../../theme/Global';

export const DeviceSettingsNote = React.forwardRef((props, ref) => {
  const { note } = props;
  const { i18n } = useI18n();
  const [value, setValue] = useState(note);
  const textFieldRef = useRef();

  const { ref: registerRef, ...registerProps } =
    props.register?.('note', {
      required: props.required,
      minLength: props.minLength,
      pattern: props.pattern ? new RegExp(props.pattern) : undefined,
    }) ?? {};

  const textField = useTextField(
    {
      'aria-label': 'Device note',
      id: 'note',
      name: 'note',
      inputElementType: 'textarea',
      inputMode: 'none',
      defaultValue: value,
      placeholder: i18n.messageFormatter('device.settings.note'),
      ...props,
      onChange: (value) => setValue(value),
    },
    textFieldRef
  );

  return (
    <S.Root>
      <S.TextareaAutosize
        {...mergeProps(textField.inputProps, registerProps)}
        ref={mergeRefs([textFieldRef, registerRef, ref])}
        minRows={3}
      />
    </S.Root>
  );
});

function S() {}
DeviceSettingsNote.S = S;

S.Root = styled.div`
  margin-top: ${su(2)};
  padding-top: ${su(2)};
  border-top: 1px solid ${theme.color.line};
`;

S.TextareaAutosize = styled(TextareaAutosize)`
  ${input}
  width: 100%;
  resize: none;
  padding: 8px;

  &::placeholder {
    color: ${theme.color.mono_300};
  }
`;
