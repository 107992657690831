import { useEffect, useMemo, useRef, useState } from 'react';
import styled from '@emotion/styled';
import debounce from 'lodash.debounce';
import TextareaAutosize from 'react-textarea-autosize';

import { mergeProps, useHover, useTextField } from 'react-aria';

import { stopPressPropagation } from '../../../../lib/dom/stopPressPropagation';
import { mergeRefs } from '../../../../lib/mergeRefs';
import { AdvancedFlowViewStore } from '../store/AdvancedFlowViewStore';
import { TokenContextProvider } from './TokenContextProvider';

import { useDraggableButton } from './useDraggableButton';
import { useDraggableNodeContext } from './DraggableNode';
import { useEffectEvent } from '../../../../hooks/useEffectEvent';
import { useI18n } from '../../../../hooks/useI18nFormatters';

import { theme } from '../../../../theme/theme';
import { scrollbars } from '../../../../theme/elements/scrollbars';
import { su } from '../../../../theme/functions/su';

import { AdvancedFlowCard } from './AdvancedFlowCard';
import { ContextMenu } from '../../../../components/common/context-menu/ContextMenu';
import { NoteCardContextMenuContent } from './NoteCardContextMenuContent';

import { noteColors } from './noteColors';
import { color } from '../../../../theme/vars/color.palette';

export function NoteCard(props) {
  const { i18n } = useI18n();

  const rootRef = useRef();
  const buttonRef = useRef();

  const draggableNodeContext = useDraggableNodeContext();

  const [isEdit, setIsEdit] = useState(false);

  // copy this section from AdvancedFlowCard for now
  // const isUnreachableNode = executionState.type === executionStateType.unreachable;
  // const isTesting = props.conditionTest === AdvancedFlowViewStore.conditionTest.testing;
  // eslint-disable-next-line no-unused-vars
  // const isTestingRoot = isTesting && props.testNodeId === props.nodeId;
  const isSaving = props.conditionSave === AdvancedFlowViewStore.conditionSave.saving;
  const isDisabled = isSaving || props.isInteractionDisabled;
  // const isDisabledStyle = isUnreachableNode ? 'unreachable' : 'readonly';
  // copy this section end

  const isSelected = AdvancedFlowViewStore.useIsSelected({ id: props.nodeId });

  const hover = useHover({});
  const button = useDraggableButton(
    {
      elementType: 'div',
      isDisabled: isDisabled,
      onPress: props.onPress,
    },
    buttonRef
  );

  const handleTextChange = useMemo(() => {
    return debounce((value) => {
      AdvancedFlowViewStore.updateCardProperty({
        nodeId: props.nodeId,
        propertyKey: 'value',
        value: value,
      });
    }, 500);
  }, [props.nodeId]);

  function handleColorChange(value) {
    AdvancedFlowViewStore.updateCardProperty({
      nodeId: props.nodeId,
      propertyKey: 'color',
      value: value,
    });
  }

  const handleEditRequest = useEffectEvent((canEdit) => {
    setIsEdit(canEdit);
    // Flush the last known text edits if any.
    handleTextChange.flush();

    if (canEdit === true && isEdit === false) {
      const textFieldElement = textFieldRef.current;
      // Disable card drag
      draggableNodeContext.setIsDisabled(true);

      // Jump to end of line with focus while text length is less than 250 characters.
      const characterCount = textFieldElement.value.length;
      let cursorPosition = characterCount < 250 ? characterCount : 0;
      textFieldElement.selectionStart = cursorPosition;
      textFieldElement.selectionEnd = cursorPosition;
      textFieldElement.focus();
    } else if (canEdit === false && isEdit === true) {
      draggableNodeContext.setIsDisabled(false);
      buttonRef.current.blur();
    }
  });

  const textFieldRef = useRef();
  const textField = useTextField(
    {
      inputElementType: 'textarea',
      'aria-label': 'Note',
      defaultValue: props.data.value,
      onChange(value) {
        handleTextChange(value);
      },
      onBlur() {
        handleEditRequest(false);
      },
      // Capture all events prevents spacedrag for example.
      onKeyDown(event) {},
      onKeyUp(event) {},
    },
    textFieldRef
  );

  useEffect(() => {
    if (isEdit === true) {
      textFieldRef.current.value = props.data.value;
    }
  }, [props.data.value, isEdit]);

  useEffect(() => {
    function handleDoubleClick() {
      handleEditRequest(true);
    }

    const buttonElement = buttonRef.current;
    buttonElement.addEventListener('dblclick', handleDoubleClick);

    return function () {
      buttonElement.removeEventListener('dblclick', handleDoubleClick);
    };
  }, [buttonRef, handleEditRequest]);

  return (
    <TokenContextProvider nodeId={props.nodeId} cardData={props.data}>
      <ContextMenu
        content={
          <NoteCardContextMenuContent
            nodeId={props.nodeId}
            card={props.card}
            data={props.data}
            onEditRequest={handleEditRequest}
            onColorChangeRequest={handleColorChange}
            onStartRequest={props.onStartRequest}
            onCopyRequest={props.onCopyRequest}
            onDuplicateRequest={props.onDuplicateRequest}
            onDeleteRequest={props.onDeleteRequest}
          />
        }
      >
        {({ isOpen, onContextMenu }) => {
          return (
            <NoteCard.Root
              // dont render as button when in edit mode
              {...mergeProps(isEdit !== true ? button.buttonProps : {}, hover.hoverProps)}
              ref={mergeRefs([props.forwardedRef, rootRef, buttonRef])}
              className={props.className}
              nodeId={props.nodeId}
              data-is-selected={isSelected}
              data-style-type={props.data.type}
              data-style-note-color={props.data.color ?? noteColors.yellow}
              onContextMenu={onContextMenu}
            >
              <NoteCard.Inner>
                {isEdit ? (
                  <NoteCard.Textarea
                    {...stopPressPropagation}
                    {...textField.inputProps}
                    ref={textFieldRef}
                    minRows={1}
                    maxRows={10}
                  />
                ) : (
                  <NoteCard.Content>
                    {props.data.value ? (
                      props.data.value
                    ) : (
                      <NoteCard.Empty>
                        {i18n.messageFormatter('flow.noteCardPlaceholder')}
                      </NoteCard.Empty>
                    )}
                  </NoteCard.Content>
                )}
              </NoteCard.Inner>
            </NoteCard.Root>
          );
        }}
      </ContextMenu>
    </TokenContextProvider>
  );
}

NoteCard.Root = styled(AdvancedFlowCard.Root)`
  background: var(--notecard-color);
  border-radius: ${theme.borderRadius.small};

  &:after {
    border-radius: 7px;
  }

  &[data-is-selected='true'] {
    background: var(--notecard-color-selected);

    &::after {
      border-color: var(--notecard-color-border);
    }
  }

  &[data-style-note-color='${noteColors.yellow}'] {
    --notecard-color: ${theme.color.note_yellow};
    --notecard-color-selected: ${theme.color.note_yellow_selected};
    --notecard-color-focus: ${theme.color.note_yellow_focus};
    --notecard-color-border: ${theme.color.note_yellow_border};
  }

  &[data-style-note-color=${noteColors.red}] {
    --notecard-color: ${theme.color.note_red};
    --notecard-color-selected: ${theme.color.note_red_selected};
    --notecard-color-focus: ${theme.color.note_red_focus};
    --notecard-color-border: ${theme.color.note_red_border};
  }

  &[data-style-note-color=${noteColors.green}] {
    --notecard-color: ${theme.color.note_green};
    --notecard-color-selected: ${theme.color.note_green_selected};
    --notecard-color-focus: ${theme.color.note_green_focus};
    --notecard-color-border: ${theme.color.note_green_border};
  }

  &[data-style-note-color=${noteColors.blue}] {
    --notecard-color: ${theme.color.note_blue};
    --notecard-color-selected: ${theme.color.note_blue_selected};
    --notecard-color-focus: ${theme.color.note_blue_focus};
    --notecard-color-border: ${theme.color.note_blue_border};
  }
`;

NoteCard.Inner = styled(AdvancedFlowCard.Inner)`
  padding: 0;
`;

NoteCard.Content = styled.div`
  ${scrollbars.dark}
  scrollbar-color: ${color.mono_o_20} transparent;

  width: calc(100% + ${su(2)});
  color: ${theme.color.text_black_o_60};
  font-weight: ${theme.fontWeight.medium};
  line-height: 22px;
  padding: ${su(2, 3, 2, 2)};
  max-height: 260px; // line-height * max rows + padding
  overflow: auto;
  cursor: default;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  word-break: break-word;
  mask-image: linear-gradient(
      180deg,
      transparent 0px,
      black ${su(2)},
      black calc(100% - ${su(2)}),
      transparent 100%
    ),
    linear-gradient(180deg, black 0%, black 100%);
  mask-size: calc(100% - ${su(2)}) 100%, ${su(2)} 100%;
  mask-position: left top, right top;
  mask-repeat: no-repeat;
`;

NoteCard.Textarea = styled(TextareaAutosize)`
  ${scrollbars.dark}
  scrollbar-color: ${color.mono_o_20} transparent;

  width: calc(100% + ${su(2)});
  color: ${theme.color.text_black_o_60};
  font-weight: ${theme.fontWeight.medium};
  line-height: 22px;
  border-radius: ${theme.borderRadius.small};
  background: transparent;
  resize: none;
  border: none;
  padding: ${su(2, 3, 2, 2)};
  transition: ${theme.duration.fast} ${theme.curve.fastIn};
  transition-property: color;

  pointer-events: auto;
  user-select: auto;

  &:focus {
    color: ${theme.color.text_black_o_80};
    background-color: var(--notecard-color-focus);
  }
`;

NoteCard.Empty = styled.span`
  font-weight: ${theme.fontWeight.regular};
  font-style: italic;
  color: ${theme.color.text_black_o_30};
`;
