import { useState, useRef, useEffect, useLayoutEffect } from 'react';
import styled from '@emotion/styled';
import { useForm } from 'react-hook-form';

import { RouteManager } from '../../../RouteManager';
import { ToastManager } from '../../../ToastManager';
import { replaceDot } from '../../../lib/replaceDot';
import { wait } from '../../../lib/wait';

import { useI18n } from '../../../hooks/useI18nFormatters';
import { useDevice } from '../../../store/devices/useDevices';

import { theme } from '../../../theme/theme';
import { su } from '../../../theme/functions/su';
import { animationFade } from '../../../theme/animations/animationFade';
import { scrollbarDark } from '../../../theme/elements/scrollbars';

import { Dialog } from '../../../components/overlay/Dialog';
import { SpinnerFade } from '../../../components/common/SpinnerFade';
import { IconButton } from '../../../components/buttons/IconButton';
import { GradientButton } from '../../../components/buttons/GradientButton';
import { DeviceStatic } from '../../../components/device/DeviceStatic';
import { Throbber } from '../../../components/common/Throbber';
import { DeviceTile } from '../../../components/device/DeviceTile';

import { DeviceSettingsGeneral } from './DeviceSettingsGeneral';
import { DeviceSettingsMaintenance } from './DeviceSettingsMaintenance';
import { DeviceSettingsAdvanced } from './DeviceSettingsAdvanced';
import { DeviceSettingsNote } from './DeviceSettingsNote';

import { iconCrossMedium } from '../../../theme/icons-v2/interface/cross-medium/cross-medium';
import { iconCheckmark } from '../../../theme/icons/interface/checkmark';

export function DeviceSettingsDialog(props) {
  const { i18n } = useI18n();
  const contentRef = useRef(null);
  const deviceTileRef = useRef(null);
  const { device, api, manager } = useDevice({ deviceId: props.deviceId });
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isScrolling, setIsScrolling] = useState(false);
  const [view, setView] = useState('loading');
  const [advancedSettings, setAdvancedSettings] = useState([]);
  const [hasScrollbar, setHasScrollbar] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({ mode: 'onBlur' });

  function handleClose() {
    RouteManager.toOmitQuery();
  }

  function formatGeneralSettings(data) {
    const formattedGeneralSettings = {};
    if (data.name) formattedGeneralSettings.name = data.name;
    if (data.zone) formattedGeneralSettings.zone = data.zone;
    if (data.icon && data.icon !== 'default') {
      formattedGeneralSettings.iconOverride = data.icon;
    } else {
      formattedGeneralSettings.iconOverride = null;
    }
    if (data.statusIndicator && data.statusIndicator !== '.anyAlarm') {
      formattedGeneralSettings.uiIndicator = data.statusIndicator;
    } else {
      formattedGeneralSettings.uiIndicator = null;
    }
    if (data.virtualClass && data.virtualClass !== 'default') {
      formattedGeneralSettings.virtualClass = data.virtualClass;
    } else {
      formattedGeneralSettings.virtualClass = null;
    }
    if (data.note) {
      formattedGeneralSettings.note = data.note;
    } else {
      formattedGeneralSettings.note = null;
    }

    return formattedGeneralSettings;
  }

  function formatAdvancedSettings(data) {
    const flatSettings = [];
    const formattedSettings = {};

    function flattenSettings(settingsArray) {
      for (const setting of settingsArray) {
        if (setting.type === 'group') {
          flattenSettings(setting.children);
        } else {
          flatSettings.push(setting);
        }
      }
    }

    flattenSettings(advancedSettings);

    for (const [key, value] of Object.entries(data)) {
      if (key.startsWith('__advanced__') === false) continue;
      const id = replaceDot.reverseReplace(key.replace('__advanced__', ''));
      const setting = flatSettings.find((setting) => setting.id === id);
      const type = setting.type;

      if (type === 'number') {
        formattedSettings[id] = Number(value);
        continue;
      }
      formattedSettings[id] = value;
    }
    return formattedSettings;
  }

  function onSubmit(data, options = {}) {
    setIsSaving(true);

    const formattedGeneralSettings = formatGeneralSettings(data);
    const formattedAdvancedSettings = formatAdvancedSettings(data);

    const waitPromise = wait(500);
    const setGeneralSettingsPromise = api.devices.updateDevice({
      id: device.id,
      device: formattedGeneralSettings,
    });
    const setAdvancedSettingsPromise = manager.setDeviceSettings({
      id: props.deviceId,
      settings: formattedAdvancedSettings,
    });

    Promise.all([waitPromise, setGeneralSettingsPromise, setAdvancedSettingsPromise])
      .then((res) => {
        if (typeof res[2]?.message === 'string') {
          ToastManager.add({
            message: res[2]?.message,
          });
        }

        setIsSaving(false);
        if (!options.preventClose) RouteManager.toOmitQuery();
      })
      .catch((error) => {
        setIsSaving(false);
        ToastManager.handleError(error);
      });
  }

  useEffect(() => {
    if (manager && props.deviceId) {
      (async () => {
        try {
          const deviceSettings = await manager.getDeviceSettingsObj({
            id: props.deviceId,
          });
          if (!deviceSettings) return;

          let newDeviceSettings = [];

          // Place nested groups under their parent group
          function flattenGroup(group) {
            let parentGroupIndex = newDeviceSettings.length - 1;
            let newChildren = [];
            for (const child of group.children) {
              if (child.type === 'group' && child.children && child.children.length > 0) {
                child.title = `${group.title} - ${child.title}`;
                newDeviceSettings.push(child);
                flattenGroup(child);
              } else {
                newChildren.push(child);
              }
            }
            if (newChildren.length > 0) {
              newDeviceSettings[parentGroupIndex].children = newChildren;
            } else {
              newDeviceSettings.splice(parentGroupIndex, 1); // Remove empty group
            }
          }

          // Create new array with nested groups flattened
          for (const setting of deviceSettings) {
            newDeviceSettings.push(setting);
            if (setting.type === 'group' && setting.children && setting.children.length > 0) {
              flattenGroup(setting);
            }
          }
          setAdvancedSettings(newDeviceSettings);
        } catch (error) {
          ToastManager.handleError(error);
        } finally {
          setTimeout(() => {
            setIsLoading(false);
          }, 1000);
        }
      })();
    }
  }, [manager, props]);

  useLayoutEffect(() => {
    const contentElement = contentRef.current;
    const deviceTileElement = deviceTileRef.current;

    function handleResize() {
      requestAnimationFrame(() => {
        if (contentElement.scrollHeight > contentElement.clientHeight) {
          setHasScrollbar(true);
        } else {
          setHasScrollbar(false);
        }
      });
    }

    function handleIntersection(entries) {
      requestAnimationFrame(() => {
        if (entries[0].intersectionRatio < 1) {
          setIsScrolling(true);
        } else {
          setIsScrolling(false);
        }
      });
    }

    const resizeObserver = new ResizeObserver(handleResize);
    resizeObserver.observe(contentElement);

    const intersectionObserver = new IntersectionObserver(handleIntersection, {
      root: null,
      threshold: [0, 1],
    });
    intersectionObserver.observe(deviceTileElement);

    return () => {
      resizeObserver.unobserve(contentElement);
      intersectionObserver.unobserve(deviceTileElement);
    };
  }, []);

  return (
    <Dialog onClose={handleClose}>
      <S.Root>
        <S.Header data-content-is-scrolling={isScrolling}>
          <S.CloseButton
            url={iconCrossMedium}
            color={theme.color.icon_light}
            size={theme.icon.size_default}
            onPress={handleClose}
          />

          <S.Title>{i18n.messageFormatter('device.settings.title')}</S.Title>
        </S.Header>

        <S.ContentWrapper ref={contentRef}>
          <S.BackgroundCircle data-content-is-scrolling={isScrolling} />

          <S.ContentForm>
            <S.DeviceTileWrapper ref={deviceTileRef}>
              <DeviceStatic deviceId={props.deviceId} tileSize={DeviceTile.size.medium} />
            </S.DeviceTileWrapper>

            {view === 'loading' && (
              <S.Loading
                data-is-fade-out={isLoading === false}
                onAnimationEnd={(event) => {
                  if (
                    isLoading === false &&
                    event.target === event.currentTarget &&
                    event.animationName === animationFade.out.name
                  ) {
                    setView('settings');
                  }
                }}
              >
                <Throbber size={theme.icon.size_default} />
              </S.Loading>
            )}

            {view === 'settings' && (
              <S.Settings data-is-fade-in={isLoading === false}>
                <DeviceSettingsGeneral
                  deviceId={props.deviceId}
                  register={register}
                  control={control}
                  errors={errors}
                />
                <DeviceSettingsMaintenance deviceId={props.deviceId} />

                {advancedSettings.length > 0 && (
                  <DeviceSettingsAdvanced
                    device={device}
                    advancedSettings={advancedSettings}
                    register={register}
                    control={control}
                    errors={errors}
                  />
                )}

                <DeviceSettingsNote
                  note={device?.note}
                  register={register}
                  control={control}
                  errors={errors}
                />
              </S.Settings>
            )}
          </S.ContentForm>
        </S.ContentWrapper>

        <S.Footer data-content-has-scrollbar={hasScrollbar}>
          <GradientButton
            styleWidth="full"
            styleFlat={true}
            onPress={() => {
              handleSubmit(onSubmit)({ preventClose: false });
            }}
          >
            <SpinnerFade
              isActive={isSaving === true}
              size={theme.icon.size_small}
              color={theme.color.white}
            >
              <GradientButton.Icon
                url={iconCheckmark}
                color={theme.color.white}
                size={theme.icon.size_small}
              />
            </SpinnerFade>
            <GradientButton.Text>{i18n.messageFormatter('common.save')}</GradientButton.Text>
          </GradientButton>
        </S.Footer>
      </S.Root>
    </Dialog>
  );
}

function S() {}

DeviceSettingsDialog.S = S;

S.Root = styled.div`
  --dialog-width: 512px;
  --dialog-height: 658px;

  display: grid;
  grid-template-rows: auto 1fr auto;
  position: relative;
  isolation: isolate;
  min-width: var(--dialog-width);
  max-width: var(--dialog-width);
  height: var(--dialog-height);
  background-color: ${theme.color.body};
  border-radius: ${theme.borderRadius.default};
  overflow: hidden;
`;

S.Header = styled.div`
  position: relative;
  z-index: 10;
  transform: translate3d(0, 0, 0); // Fix for Safari z-index bug
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${su(1.5)};
  background-color: ${theme.color.component};
  border-bottom: 1px solid transparent;
  transition: ${theme.duration.normal} ${theme.curve.easeInOut};
  transition-property: border-bottom;

  &[data-content-is-scrolling='true'] {
    border-bottom: 1px solid ${theme.color.line};
  }
`;

S.CloseButton = styled(IconButton)`
  position: absolute;
  top: calc(${su(1.5)} + 2px);
  right: ${su(1.5)};
`;

S.Title = styled.h3`
  text-align: center;
`;

S.ContentWrapper = styled.div`
  ${scrollbarDark};
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
`;

S.BackgroundCircle = styled.div`
  position: absolute;
  z-index: -1;
  top: -1956px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 4112px;
  height: 4112px;
  border-radius: 50%;
  background-color: ${theme.color.component};
`;

S.ContentForm = styled.form`
  position: absolute;
  top: 0;
  bottom: 0;
  left: ${su(2)};
  width: calc(var(--dialog-width) - ${su(4)}); // Prevent layout shift
  display: flex;
  flex-direction: column;
  align-items: center;
`;

S.DeviceTileWrapper = styled.div`
  margin-top: ${su(3)};
  padding-top: ${su(1)};
`;

S.Loading = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &[data-is-fade-out='true'] {
    animation-name: ${animationFade.out};
    animation-duration: ${theme.duration.normal};
    animation-timing-function: ${theme.curve.easeInOut};
    animation-fill-mode: forwards;
  }
`;

S.Settings = styled.div`
  width: 100%;
  opacity: 0;
  padding-bottom: ${su(4)};

  &[data-is-fade-in='true'] {
    animation-name: ${animationFade.in};
    animation-duration: ${theme.duration.normal};
    animation-timing-function: ${theme.curve.easeInOut};
    animation-fill-mode: forwards;
  }
`;

S.Footer = styled.div`
  padding: ${su(1.5)};
  border-top: 1px solid transparent;

  &[data-content-has-scrollbar='true'] {
    background-color: ${theme.color.component};
    border-top: 1px solid ${theme.color.line};
  }
`;
