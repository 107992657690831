import styled from '@emotion/styled';

import { replaceDot } from '../../../lib/replaceDot';

import { su } from '../../../theme/functions/su';

import { TextField } from '../../../components/forms/TextField';
import { TextareaField } from '../../../components/forms/TextareaField';
import { SelectControllable } from '../../../components/forms/select/SelectControllable';
import { DeviceSettingsLabel } from './DeviceSettingsLabel';
import { DeviceSettingsCheckboxSelect } from './DeviceSettingsCheckboxSelect';

export function DeviceSettingsAdvancedSetting(props) {
  const label = props.setting.label ?? props.setting.title;
  const formattedLabel =
    label != null && props.setting.units != null ? `${label} (${props.setting.units})` : label;
  // Replace the dot and reverse it on onSubmit
  // hook form has some assumptions on a field name
  // with a dot in it and this is unwanted
  const id = replaceDot.replace(props.setting.id.toString());
  const formattedName = `__advanced__${id}`;

  const settingProps = {
    key: id,
    name: formattedName,
    orientation: 'vertical',
    label: formattedLabel,
    hint: props.setting.hint,
    placeholder: props.setting.placeholder ? String(props.setting.placeholder) : undefined,
    defaultValue: props.device?.settings[props.setting.id],
    register: props.register,
    control: props.control,
  };

  function renderSetting() {
    switch (props.setting.type) {
      case 'text':
      case 'password':
      case 'number':
        return (
          <TextField
            {...settingProps}
            type={props.setting.type}
            pattern={props.setting.pattern}
            error={props.errors[settingProps.name]}
            min={props.setting.min}
            max={props.setting.max}
            step={props.setting.step}
            autoComplete="off"
          />
        );
      case 'textarea':
        return (
          <TextareaField
            {...settingProps}
            rows={6}
            pattern={props.setting.pattern}
            error={props.errors[settingProps.name]}
          />
        );
      case 'checkbox':
        return (
          <DeviceSettingsCheckboxSelect
            {...settingProps}
            defaultChecked={settingProps.defaultValue}
          >
            {(option) => {
              return (
                <DeviceSettingsCheckboxSelect.Option
                  textValue={option.label ?? option.title}
                  children={option}
                />
              );
            }}
          </DeviceSettingsCheckboxSelect>
        );
      case 'dropdown':
      case 'radio':
        return (
          <SelectControllable {...settingProps} options={props.setting.values}>
            {(option) => {
              return (
                <SelectControllable.Option
                  textValue={option.label ?? option.title}
                  children={option}
                />
              );
            }}
          </SelectControllable>
        );
      case 'label':
        return <DeviceSettingsLabel {...settingProps} />;
      default:
        // dev fallback
        return (
          <div key={props.setting.id}>
            {props.setting.label} : {props.device?.settings[props.setting.id]}
          </div>
        );
    }
  }

  return <S.Root>{renderSetting()}</S.Root>;
}

function S() {}
DeviceSettingsAdvancedSetting.S = S;

S.Root = styled.div`
  margin-top: ${su(2)};
`;
