import { useLayoutEffect } from 'react';
import { css, Global } from '@emotion/react';

import { useWebAppSettings } from '../store/web-app-settings/useWebAppSettings';

import { theme, definitionBase, definitionDark } from './theme';
import { su } from './functions/su';
import { classes } from './classes';
import { darkMode } from './classes/darkMode';
// import { reducedMotion } from './classes/reducedMotion';

export function overrideCSSVariables(definition) {
  return Object.values(definition)
    .map((value) => {
      if (typeof value === 'object') {
        return Object.values(value)
          .map((definition) => {
            return definition;
          })
          .join('');
      }
      return ``;
    })
    .join('');
}

function makeGlobalStyles({ darkMode }) {
  return css`
    :root {
      ${overrideCSSVariables(definitionBase)};
    }

    :root {
      ${darkMode && overrideCSSVariables(definitionDark)};
    }

    * {
      box-sizing: border-box;
      margin: 0;
      padding: 0;
    }

    /* *:focus {
      outline: red 1px solid !important;
      opacity: 1;
    } */

    html,
    body,
    #root,
    .${classes.overlayProvider} {
      height: 100%;
    }

    html {
      font-size: 16px; // Overwrite default browser font-size
    }

    body {
      background-color: ${theme.color.body};
      color: ${theme.color.text};
      font: 400 1rem Roboto, sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    a {
      color: ${theme.color.highlight};
    }

    strong,
    b {
      font-weight: ${theme.fontWeight.medium};
    }

    button,
    input,
    optgroup,
    select,
    textarea,
    ul {
      margin: 0;
      padding: 0;
      outline: 0;
      font: inherit;
    }

    ul {
      list-style: none;
    }

    input,
    textarea {
    }

    input[type='search']::-webkit-search-decoration,
    input[type='search']::-webkit-search-cancel-button,
    input[type='search']::-webkit-search-results-button,
    input[type='search']::-webkit-search-results-decoration {
      -webkit-appearance: none;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type='number'] {
      -moz-appearance: textfield;
    }

    fieldset {
      margin: 0;
      padding: 0;
      border: 0;
    }

    button {
      appearance: none;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border: 0;
      background: 0;
      text-decoration: none;
      cursor: pointer;
      color: ${theme.color.text};
    }

    h1 {
      font-size: ${theme.fontSize.heading1};
      font-weight: ${theme.fontWeight.bold};
      line-height: 45px;
    }

    h2 {
      font-size: ${theme.fontSize.heading2};
      font-weight: ${theme.fontWeight.medium};
    }

    h3 {
      font-size: ${theme.fontSize.heading3};
      font-weight: ${theme.fontWeight.medium};
    }

    p {
      margin-top: ${su(2)};
      margin-bottom: ${su(2)};
    }

    small {
      font-size: ${theme.fontSize.small};
    }

    label {
      font-size: ${theme.fontSize.small};
      font-weight: ${theme.fontWeight.regular};
      margin-bottom: ${su(1)};
    }
  `;
}

export function GlobalStyles() {
  const { settings } = useWebAppSettings();

  // useEffect(() => {
  //   function listener(event) {
  //     console.log(event);
  //   }

  //   document.addEventListener('focusin', listener);

  //   return function () {
  //     document.removeEventListener('focusin', listener);
  //   };
  // }, []);

  const { theme } = settings;
  useLayoutEffect(() => {
    if (theme.darkMode === true) {
      document.documentElement.classList.add(darkMode);
      document.getElementsByName('theme-color')[0].setAttribute('content', '#222329');
    } else {
      document.documentElement.classList.remove(darkMode);
      document.getElementsByName('theme-color')[0].setAttribute('content', '#ffffff');
    }

    // if (theme.reducedMotion === true) {
    //   document.documentElement.classList.add(reducedMotion);
    // } else {
    //   document.documentElement.classList.remove(reducedMotion);
    // }
  }, [theme.darkMode]);

  return <Global styles={makeGlobalStyles({ darkMode: theme.darkMode })} />;
}

export const input = css`
  color: ${theme.color.text};
  caret-color: ${theme.color.mono_1000};
  background: ${theme.color.component};
  border: 1px solid ${theme.color.line};
  border-radius: ${theme.input.border_radius};
  transition: border-color ${theme.transition.fast} ${theme.curve.fastIn};
  min-height: var(--height, 30px);

  &::placeholder {
    color: ${theme.color.mono_300};
  }

  &:hover {
    border-color: ${theme.input.border_hover};
    box-shadow: ${theme.input.boxShadow_hover};
  }

  &:focus {
    border-color: ${theme.input.border_focus};
    box-shadow: ${theme.input.boxShadow_focus};
  }

  &[data-has-error='true'] {
    border-color: ${theme.input.border_error};
    box-shadow: ${theme.boxShadow.error};
  }
`;
